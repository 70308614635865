import {
  type Palette,
  type PaletteOptions,
  type PaletteColor,
  Box,
  styled,
} from "@mui/material";

import { partialHouse, house } from "./svg";

interface ImageProps {
  width: number;
  right?: boolean;
  xs?: Record<string, string>;
}

export const Image = styled("img")<ImageProps>`
  width: ${({ width }) => width + "%"};
  border-radius: 12px;
  ${({ right }) => (right ? "margin-left: auto;" : "")};
  ${({ theme }) => theme.breakpoints.down("md")} {
    ${({ xs }) => xs}
    margin-right: auto;
    margin-left: auto;
  }
`;

type PaletteColors = {
  [K in keyof Palette]: Palette[K] extends PaletteColor ? K : never;
}[keyof PaletteOptions];

export const HouseBox = styled(Box)<{
  color?: PaletteColors;
  variant?: "house" | "partial";
}>`
  z-index: 1;
  color: ${({ theme, color = "green" }) =>
    (theme.palette as any)[color].contrastText};
  background-color: ${({ theme, color = "green" }) =>
    (theme.palette as any)[color].main};
  position: relative;
  ::before,
  ::after {
    ${({ theme, borderRadius }) =>
      borderRadius
        ? `border-radius:${
            (borderRadius as number) * theme.shape.borderRadius
          }px;`
        : ""}
    content: "";
    z-index: -1;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-image: ${({
      theme,
      color = "green",
      variant = "partialHouse",
    }) =>
      variant === "partialHouse"
        ? partialHouse((theme.palette as any)[color].dark)
        : house((theme.palette as any)[color].light)};
    background-size: ${({ variant = "partialHouse" }) =>
      variant === "partialHouse" ? "auto 150%" : "auto 120%"};
    background-position: ${({ variant = "partialHouse" }) =>
      variant === "partialHouse" ? "0 center" : "-10% -15%"};
    background-repeat: no-repeat;
    box-sizing: border-box;
  }
  ::after {
    transform: rotate(180deg);
    ${({ variant = "partialHouse" }) =>
      variant === "partialHouse" ? "" : "background-position: -5% -155%;"}
  }
`;
