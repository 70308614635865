import { json, type MetaFunction } from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";

import {
  Box,
  Typography,
  Container, //Link,
  //Divider,
  //Grid,
  Stack,
} from "@mui/material";

//import { BannerLink } from "~/components/BannerLink";
import { Footer } from "~/components/Footer";
import { Header } from "~/components/Header";
import { CardConcept } from "~/components/Home/CardConcept";
import { HomeHeroBanner } from "~/components/Home/HomeHeroBanner";
import { OwnershipSection } from "~/components/Home/OwnershipSection";
//import { VideoFeedback } from "~/components/Home/VideoFeedback";
import { ImageSection } from "~/components/ImageSection";
import { Qualities } from "~/components/Landing";
import { HappeningsSection } from "~/components/Service/HappeningsSection";
import { LitleSmileSvg } from "~/components/SmileSvg";
//import safeLink from "~/components/safeLink";
import defaultSeoImage from "~/public/imgs/website.webp";

export default () => {
  const {
    heroBanner,
    //media,
    cardsConcept,
    //mediaArticles,
    beachBanner,
    //videoFeedback,
    happenings,
    cta,
    quality,
  } = useLoaderData<typeof loader>();

  return (
    <>
      <Box position="relative">
        <Header contrast />
        <HomeHeroBanner
          title={heroBanner.title}
          imgUrl={heroBanner.image}
          url={cta.url}
          ctaLabel={cta.label}
          ctaColor={cta.color}
          //media
        />
        <Container component="section">
          <Box py={12}>
            <Stack alignItems="center">
              <Typography
                variant="h2"
                width="70%"
                textAlign="center"
                fontWeight="bold"
                color="secondary"
              >
                Plus de 5000 personnes ont reçu 100% de leur loyer pendant leurs
                absences. À votre tour !
              </Typography>
            </Stack>
          </Box>
          <CardConcept cards={cardsConcept} />
          <Stack alignItems="center" py={8}>
            <LitleSmileSvg />
            <Typography variant="body1">Aussi simple que ça</Typography>
          </Stack>
        </Container>
        <OwnershipSection />
        <Qualities {...quality} />
        <HappeningsSection {...happenings} />
        <Container component="section">
          <Stack py={10} direction="row" justifyContent="center">
            <LitleSmileSvg />
          </Stack>
        </Container>
        <ImageSection
          title={beachBanner.title}
          buttonLabel={beachBanner.button.text}
          url={cta.url}
          img={beachBanner.img}
        />
        <Footer />
      </Box>
    </>
  );
};

/*
return (
    <>
      <BannerLink />
      <Box position="relative">
        <Header contrast />
        <HomeHeroBanner
          title={heroBanner.title}
          imgUrl={heroBanner.image}
          url={cta.url}
          ctaLabel={cta.label}
          ctaColor={cta.color}
          media={media}
        />
        <Container component="section">
          <Box py={12}>
            <Stack alignItems="center">
              <Typography
                variant="h2"
                width="70%"
                textAlign="center"
                fontWeight="bold"
                color="secondary"
              >
                Plus de 5000 personnes ont reçu 100% de leur loyer pendant leurs
                absences. À votre tour !
              </Typography>
            </Stack>
          </Box>
          <CardConcept cards={cardsConcept} />
          <Stack alignItems="center" py={8}>
            <LitleSmileSvg />
            <Typography variant="body1">Aussi simple que ça</Typography>
          </Stack>
        </Container>
        <OwnershipSection />
        <Qualities {...quality} />
        <VideoFeedback clients={videoFeedback} />
        <ImageSection
          title={beachBanner.title}
          buttonLabel={beachBanner.button.text}
          url={cta.url}
          img={beachBanner.img}
        />
        <Container component="section">
          <Divider />
          <Box pt={8}>
            <Stack>
              <Typography
                variant="h4"
                component="span"
                textAlign="center"
                color="secondary.light"
              >
                ILS PARLENT AUSSI DE NOUS
              </Typography>
            </Stack>
            <Grid container spacing={4} pt={4}>
              {mediaArticles.map((article, key) => (
                <Grid
                  item
                  xs={12}
                  md={3}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  key={key}
                >
                  <Link {...safeLink(article.link)} target="_blank">
                    <Box component="img" src={article.logo} />
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Typography
            pt={4}
            pb={14}
            variant="h4"
            color="primary"
            textAlign="center"
          >
            <Link underline="none" {...safeLink("/")}>
              Voir les articles
            </Link>
          </Typography>
        </Container>
        <Footer />
      </Box>
    </>
  );
};
*/

export const loader = async () => {
  return import("~/data/pages/index").then(({ pageData }) => {
    return json({
      ...pageData,
    });
  });
};

export const meta: MetaFunction<typeof loader> = ({
  data: { title, description },
}) => ({
  title,
  description,
  "og:image": defaultSeoImage,
  "og:image:width": "1200",
  "og:image:height": "627",
  "og:image:type": "image/png",
});
