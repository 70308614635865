import {
  Box,
  Typography,
  Container,
  FormControl,
  Link,
  Stack,
} from "@mui/material";

import safeLink from "~/components/safeLink";

import { AutoCompleteInput } from "./AutoCompleteInput";

export const HomeHeroBanner: React.FC<{
  title: string;
  imgUrl: string;
  url: string;
  ctaLabel: string;
  ctaColor: string;
}> = ({ title, imgUrl, url, ctaLabel, ctaColor }) => (
  <Box
    aria-label={title}
    sx={{
      backgroundImage: `url(${imgUrl})`,
      backgroundPosition: "center center",
      backgroundSize: "cover",
    }}
    component="section"
    pt={14}
    pb={2}
  >
    <Container id="homeA">
      <Stack justifyContent="stretch" height="80vh">
        <Stack
          sx={{ width: { md: "700px" } }}
          height="100%"
          justifyContent="center"
        >
          <Typography
            color={"white"}
            variant="h1"
            component="h1"
            fontSize={{ sm: 60 }}
          >
            {title}
          </Typography>
          <FormControl sx={{ paddingTop: "60px" }}>
            <AutoCompleteInput
              url={url}
              ctaLabel={ctaLabel}
              ctaColor={ctaColor}
            />
          </FormControl>
        </Stack>
      </Stack>
    </Container>
  </Box>
);

/*
interface IMedia {
  logo: string;
  link: string;
}

export const HomeHeroBanner: React.FC<{
  title: string;
  imgUrl: string;
  url: string;
  ctaLabel: string;
  ctaColor: string;
  media: IMedia[];
}> = ({ title, imgUrl, url, media, ctaLabel, ctaColor }) => (
  <Box
    aria-label={title}
    sx={{
      backgroundImage: `url(${imgUrl})`,
      backgroundPosition: "center center",
      backgroundSize: "cover",
    }}
    component="section"
    pt={14}
    pb={2}
  >
    <Container id="homeA">
      <Stack justifyContent="stretch" height="80vh">
        <Stack
          sx={{ width: { md: "700px" } }}
          height="100%"
          justifyContent="center"
        >
          <Typography
            color={"white"}
            variant="h1"
            component="h1"
            fontSize={{ sm: 60 }}
          >
            {title}
          </Typography>
          <FormControl sx={{ paddingTop: "60px" }}>
            <AutoCompleteInput
              url={url}
              ctaLabel={ctaLabel}
              ctaColor={ctaColor}
            />
          </FormControl>
        </Stack>
        <Box
          display={{ xs: "none", md: "flex" }}
          justifyContent={"space-between"}
          pt={10}
        >
          {media.map((item, index) => (
            <Link {...safeLink(item.link)} target="_blank" key={index}>
              <Box component={"img"} height={"36px"} src={item.logo} />
            </Link>
          ))}
        </Box>
      </Stack>
    </Container>
  </Box>
);
*/
