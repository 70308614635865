import { Typography, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

interface ICardInfo {
  title: string;
  subtitle: string;
  content: string;
  image: {
    src: string;
    alt: string;
  };
}

export const CardConcept: React.FC<{
  cards: ICardInfo[];
}> = ({ cards }) => (
  <Stack
    spacing={6}
    direction={{ xs: "column", md: "row" }}
    alignItems={{ xs: "center", md: "unset" }}
  >
    {cards.map((card, index) => (
      <Card sx={{ maxWidth: 350, boxShadow: "none" }} key={index}>
        <CardMedia
          component="img"
          image={card.image.src}
          alt={card.image.alt}
          sx={{ borderRadius: 2 }}
        />
        <CardContent sx={{ paddingLeft: 0 }}>
          <Typography
            gutterBottom
            pt={2}
            variant="h5"
            component="div"
            color={"rgba(25,34,51,.5)"}
          >
            {card.title}
          </Typography>
          <Typography
            gutterBottom
            variant="h3"
            fontSize={"1.5em"}
            color={"secondary.main"}
          >
            {card.subtitle}
          </Typography>
          <Typography variant="body1" color="secondary.light">
            {card.content}
          </Typography>
        </CardContent>
      </Card>
    ))}
  </Stack>
);
