import { Link } from "@remix-run/react";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Typography, styled, Container, Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { HouseBox } from "../History/styled";

export const OwnershipSection: React.FC = () => {
  const HoverBg = styled("div")`
    :hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
    transition: all ease-in-out 0.3s;
  `;

  const ownershipItems = [
    { ownershipStatus: "Locataire", url: "/sous-louer-son-appartement" },
    { ownershipStatus: "Propriétaire", url: "/location-courte-duree" },
    { ownershipStatus: "Bailleur", url: "/bailleur" },
  ];

  return (
    <Container component="section">
      <HouseBox borderRadius={4} color="secondary" variant="house" py={3}>
        <Grid container borderRadius={4} p={5} spacing={3}>
          <Grid
            xs={12}
            md={4}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            pl={4}
          >
            <Typography color="white" variant="h1" component="span" pb={4}>
              Envie d'en savoir plus ?
            </Typography>
            <Typography color="white" variant="h1" component="span">
              Vous êtes :
            </Typography>
          </Grid>
          <Grid md={3}></Grid>
          <Grid xs={12} md={5}>
            {ownershipItems.map((item, key) => (
              <HoverBg key={key}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  borderBottom={1}
                  borderColor="white"
                  alignItems="center"
                  py={2}
                  component={Link}
                  to={item.url}
                  sx={{ textDecoration: "none" }}
                >
                  <Typography variant="h2" component="span" color="white">
                    {item.ownershipStatus}
                  </Typography>
                  <ArrowForwardIcon sx={{ color: "white" }} />
                </Stack>
              </HoverBg>
            ))}
          </Grid>
        </Grid>
      </HouseBox>
    </Container>
  );
};
